import React, { useCallback, useEffect, useState } from "react";
import API from "../../middleware/Api";
import Auth from "../../middleware/Auth";
import { useDispatch } from "react-redux";
import { Link as RouterLink, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import bs58 from "bs58";
import {
    applyThemes,
    setSessionStatus,
    toggleSnackbar,
} from "../../redux/explorer";
import {
    connectWallet,
    signMessage,
} from "../../utils/web3";
const AddressContainer = ({
    children
}) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [originalSignMessage, setOriginalSignMessage] = useState(null);
    const history = useHistory();
    const ToggleSnackbar = useCallback(
        (vertical, horizontal, msg, color) =>
            dispatch(toggleSnackbar(vertical, horizontal, msg, color)),
        [dispatch]
    );
    const ApplyThemes = useCallback(
        (theme) => dispatch(applyThemes(theme)),
        [dispatch]
    );
    const SetSessionStatus = useCallback(
        (status) => dispatch(setSessionStatus(status)),
        [dispatch]
    );
    useEffect(() => {
        API.get("/user/login/sign")
        .then((response) => {
            setOriginalSignMessage(response.data);
        })
        .catch((error) => {
            ToggleSnackbar("top", "right", error.message, "error");
        });
    }, []);


    const afterLogin = (data) => {
        Auth.authenticate(data);

        // 设置登录状态
        SetSessionStatus(true);

        // eslint-disable-next-line react-hooks/rules-of-hooks
        history.push("/home");
        window.location.reload();
        ToggleSnackbar("top", "right", t("login.success"), "success");
        localStorage.removeItem("siteConfigCache");
    };

    const base58Encoded = (str) => {
        const encoder = new TextEncoder();
        const data = encoder.encode(str);
        const base58Encoded = bs58.encode(data);
        return base58Encoded;
    };

    const signOut = () => {
        API.delete("/user/session/")
            .then(() => {
                window.location.reload();
            })
            .catch((error) => {
                ToggleSnackbar(
                    "top",
                    "right",
                    error.message,
                    "warning"
                );
            })
    };

    // 签名
    const sign = async (walletInfo) => {
        // signOut();
        const { sign = "" } = originalSignMessage;
        const encodedMsg = base58Encoded(sign);
        const signResult = await signMessage(encodedMsg, walletInfo)
            .catch(() => {
                console.log("signature catch");
            });

        if (signResult?.signature) {
            const { signature } = signResult;
            const signatureSplice =
                sign + "|" + signature + "|" + walletInfo.address;
            API.post("/user/login/sign/check", {
                type: 1,
                sign: signatureSplice,
            })
                .then((response) => {
                    if (response.rawData.code !== 203) {
                        afterLogin(response.data);
                    }
                })
                .catch((error) => {
                    ToggleSnackbar("top", "right", error.message, "warning");
                });
        }
    };

    const handleConnect = (e) => {
        e.preventDefault();
        if (!window.bw || !window.bw?.["put"]) {
            return;
        }

        if (originalSignMessage) {
            connectWallet()
                .then((res) => {
                    sign(res);
                })
                .catch((err) => {
                    console.log(err);
                })
        }
    };

    return <div onClick={handleConnect}>{children}</div>;
};

export default AddressContainer;
