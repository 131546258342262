export type WalletConnectInfo = {
    address: string;
    session: string;
}
/**
 * 链接钱包 需要安装钱包插件
 */
export const connectWallet = async () => {
    const bw = window.bw as any;
    if (!bw) {
        return Promise.reject('please install wallet plugin')
    }
    return bw['put'].connect().then(({ data }) => {
        return data
    }
    )
}
/**
 * 钱包签名
 */
export const signMessage = async (msg: string, walletConnectInfo: WalletConnectInfo) => {
    const bw = window.bw as any;
    const { session } = walletConnectInfo;
    return bw['put'].signMessage({
        message: msg,
        session: session
    }).then(({ data }) => {
        return data
    })

}