import React from "react";
import { makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
    btn: {
        position: "fixed",
        display: "inline-flex",
        alignItems: "center",
        bottom: "20px",
        left: "50%",
        transform: "translateX(-50%)",
        borderRadius: "32px",
        background: "#6330f6",
        fontWeight: 600,
        fontSize: "12px",
        padding: "12px 16px",
        gap: "6px",
        cursor: "pointer",
        lineHeight: "100%",
        color: "#FAFAFA",
    },
}));
const { REACT_APP_ENV } = process.env;

const ANDROID =
    "https://play.google.com/store/apps/details?id=com.broearn.browser";
const IOS =
    "https://apps.apple.com/ae/app/broearn-browser-for-web3-0/id6444156587?platform=iphone";

const OpenApp = () => {
    const { t } = useTranslation("common");
    const classes = useStyles();
    const userAgent = window.navigator.userAgent.toLowerCase();
    const url = REACT_APP_ENV === "prod" ? "https://cloud.broearn.com" : "https://cloud.puttest.com";
    const isBroearn = userAgent.includes("broearn");
    if (isBroearn) return null;
    const isIos = /(ios|ipad|iphone)/.test(userAgent);
    const handleOpen = () => {
        window.location.href = `broearn://dapp?url=${url}`;
        setTimeout(() => {
            window.location.href = isIos ? IOS : ANDROID;
        }, 2000);
    };
    return (
        <a className={classes.btn} onClick={handleOpen}>
            <img src={"/static/img/logo_white.svg"} width={16} />
            <span>{t("getApp.open")}</span>
        </a>
    );
};

export default OpenApp;
