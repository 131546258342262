import React, { useEffect } from "react";
import { Link, makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
    container: {
        backgroundColor: theme.palette.type !== "dark" ? "#fff" : "#1F1F1F",
        display: "flex",
        alignItems: "center",
        padding: "12px 16px",
        width: "100%",
        boxShadow: "0 6px 6px 0 rgba(0, 0, 0, 0.04)",
    },
    main: {
        display: "flex",
        alignItems: "center",
        width: "100%",
    },
    btn: {
        marginLeft: "auto",
        display: "inline-flex",
        padding: "8px 12px",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "32px",
        background: "#6330f6",
        fontSize: "12px",
        fontWeight: 600,
        textTransform: "capitalize",
        color: "#fafafa",
        lineHeight: "100%"
    },
    title: {
        fontSize: "16px",
        lineHeight: "16.017px",
        fontWeight: 500,
    },
    desc: {
        fontSize: "10px",
        whiteSpace: "nowrap",
        fontWeight: 400,
        marginTop: "4px",
    },
    content: {
        color: theme.palette.type === "dark" ? "#FAFAFA" : "#000",
        flex: 1,
        width: "200px",
        margin: "0 9px",
        overflow: "hidden",
        [theme.breakpoints.up("sm")]: {
            width: "auto",
        },
    },
}));

const ANDROID =
    "https://play.google.com/store/apps/details?id=com.broearn.browser";
const IOS =
    "https://apps.apple.com/ae/app/broearn-browser-for-web3-0/id6444156587?platform=iphone";

const GetApp = () => {
    const { t } = useTranslation("common");
    const classes = useStyles();
    const userAgent = window.navigator.userAgent.toLowerCase();
    const isBroearn = userAgent.includes("broearn");
    let timer = null;

    useEffect(() => {
        const screenWidth = window.innerWidth;
        if(!isBroearn && screenWidth < 800){
            const content = document.getElementById('content');
            const marquee = document.getElementById('marquee');
    
            let position = content.offsetWidth;
            timer = setInterval(() => {
                if (position <= -marquee.scrollWidth) {
                    position = content.offsetWidth;
                } else {
                    position -= 0.5;
                }
                marquee.style.transform = `translateX(${position}px)`;
            }, 10);
            return () => {
                clearInterval(timer);
            }
        }
    }, [])
    if (isBroearn) return null;
    const isIos = /(ios|ipad|iphone)/.test(userAgent);
    const downloadUrl = isIos ? IOS : ANDROID;
    return (
        <div className={classes.container}>
            <div className={classes.main}>
                <img
                    src="/static/img/broearn_logo.png"
                    width={36}
                    alt="broearn"
                />

                <div id="content" className={classes.content}>
                    <div className={classes.title}>Broearn</div>
                    <div id="marquee" className={classes.desc}>
                        {t("getApp.desc")}
                    </div>
                </div>

                <div className={classes.btn}>
                    <Link
                        href={downloadUrl}
                        target="_blank"
                        rel="nofollow"
                        underline="none"
                        color="inherit"
                    >
                        {t("getApp.download")}
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default GetApp;
